<template>
  <v-theme-provider dark>
    <base-section
        id="contact-us"
        class="primary"
        space="40"
    >
      <base-section-heading
          color="grey lighten-2"
          title="Mettre à jour la listes des reference"
      />
      <v-container>
        <v-row>
          <v-col cols="12" md="5">
            <v-file-input
                accept="text/csv"
                v-model="file"
                label="fichier de données csv"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row v-if="file !== null && !uploading">
          <v-btn class="mr-4" color="success" type="submit" @click="submit">
            Envoyer
          </v-btn>
        </v-row>
        <v-row v-if="uploading">
          <v-col cols="12" md="6">
            <v-progress-linear
                indeterminate
                background-color="blue-grey"
                color="lime"
            />
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>


<script>
export default {
  name: "AdminRefUploadCsv",
  data: () => ({
    file: null,
    uploading: false
  }),
  methods: {
    submit() {
      this.uploading = true;

      let formData = new FormData();
      formData.append("filetoupload", this.file);

      this.$store
          .dispatch("upload_csv", formData)
          .then(() => {
            this.uploading = false;
          })
          .catch(() => {
            this.uploading = false;
          });
    }
  }
};
</script>

<style scoped></style>
